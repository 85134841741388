<template>
  <div style="color: #005677; width: 100%">
    <v-footer dark padless>
      <v-card
        flat
        tile
        class="lighten-1 text-center"
        style="
          background-color: white;
          width: 100%;
          color: #005677;
          overflow: hidden;
        "
      >
        <v-row align="center">
          <v-col cols="12" sm="3" style="text-align: center">
            <img style="height: 150px" :src="logo" />
            <h1 :style="{fontFamily: 'Aero Matics Bold' , direction:lang=='ara'?'rtl':'ltr' }"  :id="'id'+abouttitle.id">{{abouttitle[lang]}}</h1>
          </v-col>
          <v-col cols="12" sm="7" :style="{textAlign: lang=='ara'?'right':'left' , direction:lang=='ara'?'rtl':'ltr' }">
            <p class="mx-1" style="font-size: 20px" :id="'id'+AboutContent.id">
              {{ AboutContent[lang] }}
            </p>
          </v-col>
          <v-col cols="12" sm="2">
            <h1 style="font-family: Aero Matics Bold" :id="'id'+FollowUsTitle.id">{{FollowUsTitle[lang]}}</h1>
            <br />
          <a :href="twitter" target="_blank"  style="text-decoration: underline white;">
            <v-btn icon class="md-4 pa-4">
              <v-icon dark color="#005677"> mdi-twitter </v-icon>
            </v-btn>
          </a>
          <a :href="linkedin" target="_blank"  style="text-decoration: underline white;">
            <v-btn icon class="md-4 pa-4">
              <v-icon color="#005677"> mdi-linkedin </v-icon>
            </v-btn>
          </a>
          <a :href="facebook" target="_blank"  style="text-decoration: underline white;">
            <v-btn icon class="md-4 pa-4">
              <v-icon color="#005677"> mdi-facebook </v-icon>
            </v-btn>
          </a>
          </v-col>
        </v-row>
        <v-divider style="margin: 0px"></v-divider>
        <div style="background-color: #005677; width: 100%">
          <v-card-text class="white--text">
            Made by

            <strong class="white--text"> TechnicalG ©</strong>
          </v-card-text>
        </div>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "foter",
  props: ["logo", "facebook", "twitter", "linkedin", "AboutContent"],
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    show: false,
    responseMessage: { response: "" },
    selected: 5,
  }),
  computed: {
    lang() {
      return this.$store.state.lang;
    },
    abouttitle()
    {
      return this.$store.state.abouttitle;
    },
    FollowUsTitle(){
      return this.$store.state.FollowUsTitle;
    }
  },
};
</script>
