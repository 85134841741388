import Repository from "@/repositories/Base/Repository";

export default class pheaderRepository extends Repository {
  constructor(datasource) {
    super(datasource);
  }

  team(){
    return this.datasource.get(`getPhotoTeam`).then(({data}) => data)
  }

  
}