<template>
  <v-container class="md-3" style="margin-bottom:30px;" v-show="products.length">
    <v-row justify="center" class="md-3">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-3 secTitle"
            style="font-size: 60px; width: fit-content"
            :id="'id'+title.id"
          >
            {{title[lang]}}
          </h1>
        </div>
      </v-col>
    </v-row>
    <carousel-3d
      :autoplay="true"
      style="height: 58vh"
      data-aos-duration="1000"
      data-aos="zoom-in"
    >
      <slide
      
        v-for="(service, index) in products"
        :index="index"
        :key="index"
        style="
          height: 58vh;
          background-color: #c7e5fe;
          text-align: center;
          border-radius: 5px;
        "
      >
        <img :src="origin + service.photos[0]" style="height: 80%" />

        <figure>
          <figcaption>
            <h4 style="margin-top: 10px; color: #006ab3">
              {{ service["title"][lang] }}
            </h4>
          </figcaption>
        </figure>
      </slide>
    </carousel-3d>
    <v-row justify="center">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-3 secTitle"
            style="font-size: 60px; width: fit-content"
          >
            <v-btn elevation="2" outlined color="#005677" to="/products">
              {{showallbtn[lang]}}
               </v-btn>
          </h1>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  computed: {
    products() {
      return this.$store.state.products;
    },
    origin() {
      return this.$store.state.origin;
    },
    lang()
    {
      return this.$store.state.lang;
    },
    title(){
        return this.$store.state.productsTitle;
    },
    showallbtn()
    {
        return this.$store.state.showallbtn;
    }
  },
};
</script>
<style lang="scss" scoped>
.example-3d {
  width: 100%;
  height: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 200px;
    background-color: #2c8dfb;
    background-position: center;
    background-size: cover;
    color: white;
  }

  .swiper-pagination {
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: white;
    }
  }
}

.carousel-3d-container figure {
  margin: 0;
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: #ffffffd8;
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}
.img {
  height: 70%;
  width: auto;
}
</style>
