<template>
  <v-container style="margin-bottom: 10vw; margin-top: 50px" v-show="exists">
    <v-row justify="center">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-3 secTitle"
            style="font-size: 60px; width: fit-content"
            :id="'id'+title.id"
          >
            {{ title[lang] }}
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="4"
        v-for="(blog, index) in blogs"
        :key="index"
        style="min-height: 400pt; text-align: center"
      >
        <v-card
          style="
            width: 100%;
            height: 549px;
            overflow: hidden;
            padding-bottom: 10px;
          "
          data-aos-duration="1000"
          data-aos="fade-up"
          :data-aos-delay="100 * index"
        >
          <img
            :src="origin + blog.photos[0]"
            alt=""
            style="height: 200px; width: 200px"
          />
          <h3>
            {{ blog.title[title] }}
          </h3>
          <div
            style="
              text-align: left;
              padding: 15px;
              padding-bottom: 13px;
              max-height: 53%;
              overflow: hidden;
            "
          >
            <p>
              {{ blog.publishedOn }}
              <br />
              <br />
              {{ blog.description[lang] }}
            </p>
          </div>
          <div style="width: 100%; height: 20px"></div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-3 secTitle"
            style="font-size: 60px; width: fit-content"
          >
            <v-btn elevation="2" outlined color="#005677" to="/blogs">
              {{showallbtn[lang]}}
            </v-btn>
          </h1>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  computed: {
    blogs() {
      return this.$store.state.blogs.slice(-3);
    },
    origin() {
      return this.$store.state.origin;
    },
    title() {
      return this.$store.state.blogsTitle;
    },
    lang() {
      return this.$store.state.lang;
    },
    showallbtn()
    {
     return this.$store.state.showallbtn;
    },
    exists() {
      if(this.$store.state.blogs.length == 0)
      {
        return 0;
      }
      else
      {
        return 1;
      }
    }

  },
  methods:{

  }

};
</script>
