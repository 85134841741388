import Repository from "@/repositories/Base/Repository";

export default class feedbackRepository extends Repository {
  constructor(datasource) {
    super(datasource);
  }

  all(){
    return this.datasource.get(`getFeedbacks`).then(({data}) => data)
  }
  sendForm(messageData) {
    return this.datasource.post(`FeedbackForm`,messageData).then(({data}) => data)
  }
  
}