<template>
  <v-container style="margin-bottom: 10vw" v-show="lEvents.length">
    <v-row justify="center" class="md-3">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-3 secTitle"
            style="font-size: 60px; width: fit-content"
            :id="'id'+title.id"
          >
            {{ title[lang] }}
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-card elevation="2">
      <v-carousel
        hide-delimiters
        cycle
        height="fit-content"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <v-carousel-item v-for="(item, i) in lEvents" :key="i">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="4">
              <div style="min-height: 300px">
                <img
                  :src="origin + item.photos[0]"
                  @load="oload"
                  style="width: 400px; height: 290px; max-width: 100%"
                />
              </div>
            </v-col>

            <v-col cols="12" sm="7">
              <h2>
                {{ item.Name[lang] }}
              </h2>
              <p style="font-size: 15px" >
                {{from[lang]}} : {{ item.start.substring(0, 10).split("-").reverse().join("-") }} {{to[lang]}} :
                {{ item.end.substring(0, 10).split("-").reverse().join("-") }}
              </p>
              <p style="font-size: 20px">
                {{ item.description[lang] }}
              </p>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-container>
</template>
<script>
export default {
  props: ["ourCustomerTitle", "lEvents", "multiple"],
  computed: {
    origin() {
      return this.$store.state.origin;
    },
    title() {
      return this.$store.state.LastEventsTitle;
    },
    lang() {
      return this.$store.state.lang;
    },
    from()
    {
      return this.$store.state.from;
    },
    to()
    {
      return this.$store.state.to;
    }
    
  },
  methods: {
    oload() {
      this.$store.commit("setoverlay", { overlay: false });
    },
  },
};
</script>
<style scoped></style>
