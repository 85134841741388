import Repository from "@/repositories/Base/Repository";

export default class calenderRepository extends Repository {
  constructor(datasource) {
    super(datasource);
  }
  all() {
      const D = new Date();
      let month = D.getMonth();
      month = month+1;
      let year = D.getFullYear();
      
    return this.datasource.get(`getAllCalender/${month}/${year}`).then(({data}) => data)
  }
  get(month, year) {
    return this.datasource.get(`getAllCalender/${month}/${year}`).then(({data}) => data)
  }
  last()
  {
      return this.datasource.get(`getOurEventCalender`).then(({data}) => data)
  }
  memo()
  {
    return this.datasource.get(`getMemories`).then(({data}) => data)
  }
}