import datasource from "@/datasource";
import SettingRepository from "@/repositories/setting" ;
import galaryRepository from '@/repositories/gallery';
import servicesRepository from '@/repositories/services';
import contactRepository from '@/repositories/contact';
import textRepository from '@/repositories/Text';
import customerRepository from '@/repositories/customer';
import newsRepository from '@/repositories/news';
import aboutRepository from '@/repositories/about';
import activationRepository from '@/repositories/activation';
import whyusRepository from '@/repositories/whyus';
import picsRepository from '@/repositories/pics';
import teamRepository from '@/repositories/team';
import calenderRepository from '@/repositories/calender';
import productsRepository from '@/repositories/products';
import historyRepository from '@/repositories/history';
import feedbackRepository from '@/repositories/feedback';
import pheaderRepository from '@/repositories/pheader';
import videoRepository from '@/repositories/videos';
const definitions = {

'settingRepo' : new SettingRepository(datasource),
'galaryRepo': new galaryRepository(datasource),
'servicesRepo':new servicesRepository(datasource),
'contactRepo' : new contactRepository(datasource),
'textRepo':new textRepository(datasource),
'customerRepo':new customerRepository(datasource),
'newsRepo':new newsRepository(datasource),
'aboutRepo':new aboutRepository(datasource),
'activationRepo' : new activationRepository(datasource),
'whyusRepo' : new whyusRepository(datasource),
'picsRepo' : new picsRepository(datasource),
'teamRepo' : new teamRepository(datasource),
'calenderRepo': new calenderRepository(datasource),
'productsRepo' : new productsRepository(datasource),
'historyRepo' : new historyRepository(datasource),
'feedbackRepo': new feedbackRepository(datasource),
'pheaderRepo' : new pheaderRepository(datasource),
'videoRepo': new videoRepository(datasource),

}

export default {
    get: (name) => definitions[name]
  }