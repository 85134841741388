import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import RepositoryFactory from './repositories/RepositoryFactory'
import Carousel3d from 'vue-carousel-3d';
import vuetimeline from "@growthbunker/vuetimeline";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/fonts/font.css'
import store from './store'


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Carousel3d)

Vue.use(vuetimeline)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  
  provide:()=>({
    settingRepo : RepositoryFactory.get('settingRepo'),
    servicesRepo : RepositoryFactory.get('servicesRepo'),
    textRepo : RepositoryFactory.get('textRepo'),
    contactRepo : RepositoryFactory.get('contactRepo'),
    whyusRepo: RepositoryFactory.get('whyusRepo'),
    teamRepo: RepositoryFactory.get('teamRepo'),
    customerRepo : RepositoryFactory.get('customerRepo'),
    newsRepo: RepositoryFactory.get('newsRepo'),
    calenderRepo: RepositoryFactory.get('calenderRepo'),
    productsRepo: RepositoryFactory.get('productsRepo'),
    historyRepo : RepositoryFactory.get('historyRepo'),
    feedbackRepo : RepositoryFactory.get('feedbackRepo'),
    pheaderRepo : RepositoryFactory.get('pheaderRepo'),
    videoRepo: RepositoryFactory.get('videoRepo')
  }),

  store,
  render: h => h(App)
}).$mount('#app')
