<template>
  <div
    class="navcard pa-0"
    style="
      background-color: white;
      width: 100vw;
      padding: 0px;
      height: fit-content;
    "
  >
    <b-navbar
      toggleable="lg"
      type="light"
      variant="white"
      style="
        background-color: white;
        padding-top: 0px;
        padding-bottom: 0px;
        width: 100%;
      "
      class="container"
    >
      <b-navbar-brand href="#"
        ><img :src="logo" style="height: 50px; width: 50px"
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            to="/"
            exact-active-class="active"
            :id="'id' + HomeBtn.id"
          >
            {{ HomeBtn[lang] }}
          </b-nav-item>
          <b-nav-item
            to="/ourteam"
            exact-active-class="active"
            :id="'id' + OurTeamBtn"
          >
            {{ OurTeamBtn[lang] }}
          </b-nav-item>
          <b-nav-item
            to="/Events"
            exact-active-class="active"
            :id="'id' + EventsButton"
          >
            {{ EventsButton[lang] }}
          </b-nav-item>
          <b-nav-item
            to="/blogs"
            exact-active-class="active"
            :id="'id' + blogsButton.id"
            v-show="blogs.length"
          >
            {{ blogsButton[lang] }}
          </b-nav-item>
          <b-nav-item
            to="/feedback"
            exact-active-class="active"
            :id="'id' + feedbackTitle.id"
          >
            {{ feedbackTitle[lang] }}
          </b-nav-item>
          <b-nav-item
            to="/memories"
            exact-active-class="active"
            :id="'id' + memoriesTitle.id"
          >
            {{ memoriesTitle[lang] }}
          </b-nav-item>
          <b-nav-item
            to="/products"
            exact-active-class="active"
            :id="'id' + productsTitle.id"
            v-show="products.length"
          >
            {{ productsTitle[lang] }}
          </b-nav-item>
          <b-nav-item
            to="/history"
            exact-active-class="active"
            :id="'id' + historyTitle.id"
          >
            {{ historyTitle[lang] }}
          </b-nav-item>
          <b-nav-item
            to="/videos"
            exact-active-class="active"
            :id="'id' + historyTitle.id"
          >
            {{ videobtn[lang] }}
          </b-nav-item>
          <b-nav-item-dropdown :text="language[lang]" right>
            <b-dropdown-item href="#" @click="changelang('eng')"
              >ENG</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changelang('ara')"
              >ARA</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changelang('ned')"
              >NED</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <a
            :href="twitter"
            target="_blank"
            style="text-decoration: underline white"
          >
            <v-btn icon class="md-4 pa-4">
              <v-icon dark color="#005677"> mdi-twitter </v-icon>
            </v-btn>
          </a>
          <a
            :href="linkedin"
            target="_blank"
            style="text-decoration: underline white"
          >
            <v-btn icon class="md-4 pa-4">
              <v-icon color="#005677"> mdi-linkedin </v-icon>
            </v-btn>
          </a>
          <a
            :href="facebook"
            target="_blank"
            style="text-decoration: underline white"
          >
            <v-btn icon class="md-4 pa-4">
              <v-icon color="#005677"> mdi-facebook </v-icon>
            </v-btn>
          </a>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
export default {
  name: "midnav",
  props: [
    "HomeBtn",
    "OurTeamBtn",
    "thirdButton",
    "facebook",
    "twitter",
    "linkedin",
    "logo",
    "blogsButton",
    "EventsButton",
    "historyTitle",
    "memoriesTitle",
    "productsTitle",
    "feedbackTitle",
    "language",
  ],
  computed: {
    origin() {
      return this.$store.state.origin;
    },
    lang() {
      return this.$store.state.lang;
    },
    products() {
      return this.$store.state.products;
    },
    blogs() {
      return this.$store.state.blogs.slice(-3);
    },
    videobtn() {
      return this.$store.state.videobtn;
    },
  },
  methods: {
    changelang(l) {
      this.$store.state.lang = l;
      document.cookie = "clang" + "=" + l + ";" + 3600 + ";path=/";
    },
  },
};
</script>
<style scoped>
.navcard {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 40px;
  z-index: 100;
  background-color: rgba(red, green, blue, 0.5);
}
.active {
  border-bottom: 3px solid #005677;
}
.a {
  border: none;
  text-decoration: underline white;
}
</style>
