import Repository from "@/repositories/Base/Repository";

export default class videoRepository extends Repository {
  constructor(datasource) {
    super(datasource);
  }

  all(){
    return this.datasource.get(`getVideos`).then(({data}) => data)
  }

  
}