<template>
  <v-container style="margin-bottom: 10vw">
    <v-row justify="center">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-3 secTitle"
            style="font-size: 60px; width: fit-content"
            :id="'id'+ourCustomerTitle.id"
          >
            {{ ourCustomerTitle[lang] }}
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-carousel
      hide-delimiters
      :show-arrows="multiple"
      cycle
      height="fit-content"
    >
      <v-carousel-item v-for="(item, i) in ourCustomerDetails" :key="i">
        <v-row justify="center" align="start">
          <v-col cols="12" sm="3">
            <div>
              <img
                :src="origin + item.photos[0]"
                style="  width:300px; height:300px; max-width:100%;"
                data-aos="fade-up-right"
                data-aos-duration="1000"
                data-aos-delay="300"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="7" :style="{textAlign: lang=='ara'?'right':'left' , direction: lang=='ara'?'right':'left' , height:'300px;' }">
            <img
              v-for="i in 5"
              :key="i"
              src="@/assets/images/star-11.svg"
              style="height: 30px; margin-top:3vw;"
              data-aos="fade-right"
            
            />
            <h2
              data-aos="fade-up-left"
              data-aos-duration="1000"
              data-aos-delay="300"
              style=" margin-top:20px;"
            >
              {{ item.clientName }}
            </h2>
            <p
              data-aos="fade-up-left"
              data-aos-duration="1000"
              data-aos-delay="300"
              style="font-size: 20px"
            >
              {{ item.description[lang] }}
            </p>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>
<script>
export default {
  props: ["ourCustomerTitle", "ourCustomerDetails", "multiple"],
  computed: {
    origin() {
      return this.$store.state.origin;
    },
    lang() {
      return this.$store.state.lang;
    },
  },
};
</script>
<style scoped></style>
