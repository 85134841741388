<template>
  <v-container style="margin-bottom: 10vw">
    <v-row justify="center" style="margin-bottom:20px;" >
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-4 md-5 secTitle"
            :style="{fontSize: '60px', width: 'fit-content', direction:lang=='ara'?'rtl':'ltr'} "
            :id="'id'+ WhyusTitle.id"
            
          >
            {{WhyusTitle[lang]}}
          </h1>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
      <v-col cols="12" sm="4" style="display: contents">
        <iframe
          width="560"
          height="315"
          :src="video"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        ></iframe>
      </v-col>
    </v-row> -->
    <v-row justify="center" style="margin-top:30px;">
      <v-col
        cols="12"
        sm="4"
        data-aos-duration="1000"
        data-aos="zoom-out-up"
        data-aos-anchor-placement="bottom-bottom"
        v-for="(r,index) in whyUsDetails"
        :key="index"

      >
        <div style="width: 100%; text-align: center">
          <v-img :src="origin+r.photos[0]" height="95" contain style="margin-bottom:20px;"/>
          <h3 :style="{direction:lang=='ara'?'rtl':'ltr'}" ><strong> {{r.title[lang]}}</strong></h3>
          <p :style="{direction:lang=='ara'?'rtl':'ltr'}">
            {{r.description[lang]}}
          </p>
        </div>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "whyUs",
  props: ['WhyusTitle','whyUsDetails'],
  data() {
    return {
      result: " https://www.youtube.com/embed/bNmPQnDHa0Q",
    };
  },
  computed:{
    origin()
    {
      return this.$store.state.origin
    },
    video(){
      return this.$store.state.video
    },
    lang()
    {
      return this.$store.state.lang;
    }
  }
};
</script>
