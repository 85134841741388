<template>
  <div id="app" class="para">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <img
          src="@/assets/images/Header.png"
          style="width: 100%; margin-top:30px;"
          data-aos="fade-up-left"
          data-aos-duration="1000"
          data-aos-delay="300"
          
        />
      </v-col>
      <v-col cols="12" sm="6" style="text-align: center">
        <h1
          class="headerTitle"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          Technical G
        </h1>
        <h6
          class="headerSecondary"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          TECHNICAL SOLUTIONS & TRADE
        </h6>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["logo", "facebook", "twitter", "linkedin", "pic", "slogan"],
  icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  computed: {
    overlay() {
      return this.$store.state.overlay;
    },
  },
};
</script>
<style scoped>
.para {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  text-align: right;
  direction: rtl;
}
.headerTitle {
  color: #005677;

  font-size: calc(3.5rem + 2vw);
}
.headerSecondary {
  color: #00a7e1;
  font-size: calc(0.89rem + 1vw);
}
</style>
