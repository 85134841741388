<template>
  <v-container ref="con" id="CONTACT">
    <v-row data-aos="flip-right" data-aos-duration="1000">
      <v-col cols="12" sm="6">
        <b-form @submit.prevent="sendContactForm($event)">
          <div
            :style="{
              marginBottom: '10px',
              direction: lang == 'ara' ? 'rtl' : 'ltr',
              textAlign: lang == 'ara' ? 'right' : 'left',
              width: '100%',
            }"
          >
            <h1
              class="font-weight-bold mb-3 secTitle"
              style="font-size: 60px; width: fit-content"
              :id="'id' + ContactUs.id"
            >
              {{ ContactUs[lang] }}
            </h1>
          </div>
          <b-form-input
            required
            name="name"
            id="name"
            :placeholder="fname[lang] + '  *'"
            :style="{
              marginBottom: '10px',
              direction: lang == 'ara' ? 'rtl' : 'ltr',
              textAlign: lang == 'ara' ? 'right' : 'left',
            }"
          >
          </b-form-input>
          <b-form-input
            required
            name="email"
            id="email"
            :placeholder="femail[lang] + '  *'"
            type="email"
            :style="{
              marginBottom: '10px',
              direction: lang == 'ara' ? 'rtl' : 'ltr',
              textAlign: lang == 'ara' ? 'right' : 'left',
            }"
          >
          </b-form-input>
          <b-form-input
            name="phone"
            id="phone"
            :placeholder="fphone[lang]"
            type="number"
            :style="{
              marginBottom: '10px',
              direction: lang == 'ara' ? 'rtl' : 'ltr',
              textAlign: lang == 'ara' ? 'right' : 'left',
            }"
          >
          </b-form-input>
          <b-form-input
            required
            name="subject"
            id="subject"
            :placeholder="fsubject[lang] + '  *'"
            :style="{
              marginBottom: '10px',
              direction: lang == 'ara' ? 'rtl' : 'ltr',
              textAlign: lang == 'ara' ? 'right' : 'left',
            }"
          >
          </b-form-input>
          <b-form-textarea
            required
            name="message"
            id="message"
            :placeholder="fdescription[lang] + '  *'"
            style="height: 150px"
            :style="{
              marginBottom: '10px',
              direction: lang == 'ara' ? 'rtl' : 'ltr',
              textAlign: lang == 'ara' ? 'right' : 'left',
            }"
          >
          </b-form-textarea>
          <b-button
            block
            style="background-color: #005677; margin-top: 10px; width: 100%"
            type="submit"
          >
            {{ csend[lang] }}
          </b-button>
        </b-form>
      </v-col>
      <v-col cols="12" sm="6">
        <div
          style="
            width: 100%;
            background-color: #005677;
            height: 100%;
            text-align: center;
            padding: 18px;
          "
        >
          <div
            class="contact-item"
            style="background-color: white; border-radius: 5px"
          >
            <v-row style="padding: 10px" justify="center">
              <v-col cols="12" style="height: 50px">
                <v-icon large color="#005677"> mdi-email </v-icon>
              </v-col>
              <h4 style="font-size: 15pt">{{ email }}</h4>
            </v-row>
          </div>
          <div
            class="contact-item"
            style="background-color: white; border-radius: 5px"
          >
            <v-row style="padding: 10px" justify="center">
              <v-col cols="12" style="height: 50px">
                <v-icon large color="#005677"> mdi-phone </v-icon>
              </v-col>
              <h4 style="font-size: 15pt">{{ phone }}</h4>
            </v-row>
          </div>
          <div
            class="contact-item"
            style="
              background-color: white;
              border-radius: 5px;
              text-align: center;
            "
          >
            <v-row style="padding: 10px" justify="center">
              <v-col cols="12" style="height: 50px">
                <v-icon large color="#005677"> mdi-map-marker </v-icon>
              </v-col>
              <h4 style="font-size: 15pt">
                {{ street }}<br />
                {{ city }}<br />
                {{ address }}
              </h4>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  inject: ["contactRepo"],
  props: ["ContactUs"],
  methods: {
    sendContactForm(evt) {
      const messageData = new FormData(evt.target);

      this.contactRepo.sendForm(messageData).then((resp) => {
        if (resp["success"]) {
          alert("Thanks for your message, we will reach back soon :)");
          this.$bvModal.hide("contact-us");
        } else {
          this.responseMessage.response = resp["data"];
        }
      });
    },
  },
  computed: {
    email() {
      return this.$store.state.email;
    },
    phone() {
      return this.$store.state.phone;
    },
    city() {
      return this.$store.state.city;
    },
    street() {
      return this.$store.state.street;
    },
    address() {
      return this.$store.state.address;
    },
    lang() {
      return this.$store.state.lang;
    },
    fname() {
      return this.$store.state.fname;
    },
    femail() {
      return this.$store.state.femail;
    },
    fphone() {
      return this.$store.state.fphone;
    },
    fsubject() {
      return this.$store.state.fsubject;
    },
    fdescription() {
      return this.$store.state.fdescription;
    },
    csend() {
      return this.$store.state.csend;
    },
  },
};
</script>
<style scoped>
.form-control {
  margin-top: 20px;
}
.contact-item {
  width: 70%;
  padding: 18 px 12 px 18 px 34 px;
  transition: all 0.5s ease;
  border: 2 px solid #fff;
  margin: 12%;
  margin-top: 5%;
}
</style>
