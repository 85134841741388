<template>
  <div style="width: 100%">
    <parallax-header />
    <services
      :servicesTitle="servicesTitle"
      :servicesDetails="servicesDetails"
    />
    <our-products/>
    <why-us :WhyusTitle="WhyusTitle" :whyUsDetails="whyUsDetails" />
    <customer :ourCustomerTitle="ourCustomerTitle" :ourCustomerDetails="ourCustomerDetails" :multiple="multiple" />
    <last-news/>
    <last-events :lEvents="lEvents"/>
    <contact-us :ContactUs="ContactUs" />
    
  </div>
</template>

<script>
import ContactUs from "../components/contactUs.vue";
import Customer from "../components/Customer.vue";
import LastEvents from '../components/LastEvents.vue';
import LastNews from '../components/lastNews.vue';
import ParallaxHeader from "../components/parallaxHeader.vue";
import Services from "../components/Services.vue";
import WhyUs from "../components/whyUs.vue";
import ourProducts from "../components/ourProducts.vue";

export default {
  name: "Home",
  inject: ["servicesRepo", "whyusRepo","customerRepo", "calenderRepo"],
  components: {
    ParallaxHeader,
    Customer,
    Services,
    ContactUs,
    WhyUs,
    LastEvents,
    LastNews,
    ourProducts
  },
  data() {

       return {
      servicesDetails: {},
      whyUsDetails: {},
      ourCustomerDetails:[],
      multiple:false,
      lEvents:[]
      
    };
  },
  computed: {
    servicesTitle() {
      return this.$store.state.ServicesTitle;
    },
    WhyusTitle() {
      return this.$store.state.WhyusTitle;
    },
    ourCustomerTitle() {
      return this.$store.state.ourCustomerTitle;
    },
    ContactUs() {
      return this.$store.state.ContactUs;
    },
  },
  mounted() {
    this.servicesRepo
      .all()
      .then((response) => (this.servicesDetails = response["data"]));
    this.whyusRepo
      .all()
      .then((response) => (this.whyUsDetails = response["data"]));
    this.customerRepo.all().then((response) => {this.ourCustomerDetails = response["data"];
      if(this.ourCustomerDetails.length > 1) {this.multiple=true;}});
    this.calenderRepo.last().then((response)=>{this.lEvents=response["data"]})
  },
};
</script>
