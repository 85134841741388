import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ServicesTitle:"",
    WhyusTitle:"",
    ourCustomerTitle:"",
    ContactUs:"",
    origin:"https://intelligent-bohr.85-215-170-134.plesk.page/",
    overlay:true,
    logo:"",
    phone:"",
    email:"",
    street:"",
    city:"",
    address:"",
    blogs:[],
    products:[],
    history:[],
    video:"",
    lang:"eng",
    blogsTitle:[],
    LastEventsTitle:[],
    calenderTitle:[],
    productsTitle:{},
    contactUsBtn:{},
    showallbtn:{},
    abouttitle:{},
    FollowUsTitle:{},
    memoriesTitle:{},
    ourtimeline:{},
    feedbackTitle:{},
    feedbackBtn:{},
    feedbacks:{},
    fname:{},
    femail:{},
    fphone:{},
    fsubject:{},
    fdescription:{},
    theader:[],
    teammore:{},
    from:{},
    to:{},
    choosefile:{},
    selectrate:{},
    cfeedtitle:{},
    cfeeddescription:{},
    csend:{},
    videobtn:{},
  },
  mutations: {
    setText(state,payload) 
    {
      state.ServicesTitle= payload.ServicesTitle;
      state.WhyusTitle=payload.WhyusTitle;
      state.ContactUs= payload.ContactUs;
      state.ourCustomerTitle= payload.ourCustomerTitle;
    },
    setoverlay(state,payload){
      state.overlay= payload.overlay;
    }
  },
  actions: {
  },
  modules: {
  }
})
