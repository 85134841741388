var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"con",attrs:{"id":"CONTACT"}},[_c('v-row',{attrs:{"data-aos":"flip-right","data-aos-duration":"1000"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendContactForm($event)}}},[_c('div',{style:({
            marginBottom: '10px',
            direction: _vm.lang == 'ara' ? 'rtl' : 'ltr',
            textAlign: _vm.lang == 'ara' ? 'right' : 'left',
            width: '100%',
          })},[_c('h1',{staticClass:"font-weight-bold mb-3 secTitle",staticStyle:{"font-size":"60px","width":"fit-content"},attrs:{"id":'id' + _vm.ContactUs.id}},[_vm._v(" "+_vm._s(_vm.ContactUs[_vm.lang])+" ")])]),_c('b-form-input',{style:({
            marginBottom: '10px',
            direction: _vm.lang == 'ara' ? 'rtl' : 'ltr',
            textAlign: _vm.lang == 'ara' ? 'right' : 'left',
          }),attrs:{"required":"","name":"name","id":"name","placeholder":_vm.fname[_vm.lang] + '  *'}}),_c('b-form-input',{style:({
            marginBottom: '10px',
            direction: _vm.lang == 'ara' ? 'rtl' : 'ltr',
            textAlign: _vm.lang == 'ara' ? 'right' : 'left',
          }),attrs:{"required":"","name":"email","id":"email","placeholder":_vm.femail[_vm.lang] + '  *',"type":"email"}}),_c('b-form-input',{style:({
            marginBottom: '10px',
            direction: _vm.lang == 'ara' ? 'rtl' : 'ltr',
            textAlign: _vm.lang == 'ara' ? 'right' : 'left',
          }),attrs:{"name":"phone","id":"phone","placeholder":_vm.fphone[_vm.lang],"type":"number"}}),_c('b-form-input',{style:({
            marginBottom: '10px',
            direction: _vm.lang == 'ara' ? 'rtl' : 'ltr',
            textAlign: _vm.lang == 'ara' ? 'right' : 'left',
          }),attrs:{"required":"","name":"subject","id":"subject","placeholder":_vm.fsubject[_vm.lang] + '  *'}}),_c('b-form-textarea',{staticStyle:{"height":"150px"},style:({
            marginBottom: '10px',
            direction: _vm.lang == 'ara' ? 'rtl' : 'ltr',
            textAlign: _vm.lang == 'ara' ? 'right' : 'left',
          }),attrs:{"required":"","name":"message","id":"message","placeholder":_vm.fdescription[_vm.lang] + '  *'}}),_c('b-button',{staticStyle:{"background-color":"#005677","margin-top":"10px","width":"100%"},attrs:{"block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.csend[_vm.lang])+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticStyle:{"width":"100%","background-color":"#005677","height":"100%","text-align":"center","padding":"18px"}},[_c('div',{staticClass:"contact-item",staticStyle:{"background-color":"white","border-radius":"5px"}},[_c('v-row',{staticStyle:{"padding":"10px"},attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"height":"50px"},attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"large":"","color":"#005677"}},[_vm._v(" mdi-email ")])],1),_c('h4',{staticStyle:{"font-size":"15pt"}},[_vm._v(_vm._s(_vm.email))])],1)],1),_c('div',{staticClass:"contact-item",staticStyle:{"background-color":"white","border-radius":"5px"}},[_c('v-row',{staticStyle:{"padding":"10px"},attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"height":"50px"},attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"large":"","color":"#005677"}},[_vm._v(" mdi-phone ")])],1),_c('h4',{staticStyle:{"font-size":"15pt"}},[_vm._v(_vm._s(_vm.phone))])],1)],1),_c('div',{staticClass:"contact-item",staticStyle:{"background-color":"white","border-radius":"5px","text-align":"center"}},[_c('v-row',{staticStyle:{"padding":"10px"},attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"height":"50px"},attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"large":"","color":"#005677"}},[_vm._v(" mdi-map-marker ")])],1),_c('h4',{staticStyle:{"font-size":"15pt"}},[_vm._v(" "+_vm._s(_vm.street)),_c('br'),_vm._v(" "+_vm._s(_vm.city)),_c('br'),_vm._v(" "+_vm._s(_vm.address)+" ")])],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }