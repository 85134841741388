<template>
  <v-container style="margin-bottom: 10vw">
    <v-row justify="center">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-3 secTitle"
            :style="{fontSize: '60px', width: 'fit-content', direction:lang=='ara'?'rtl':'ltr'}"
            :id="'id'+servicesTitle"
          >
            {{ servicesTitle[lang] }}
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="4"
        v-for="(service, index) in servicesDetails"
        :key="index"
        data-aos-duration="1000"
        data-aos="zoom-out-up"
        data-aos-anchor-placement="bottom-bottom"
      >
        <div style="width: 100%; text-align: center">
          <img
            :src="origin + service.servicesPhoto[0]['servicesPhotos']"
            height="95"
            style="margin-bottom:20px;"
          />
          <h3 :style="{direction:lang=='ara'?'rtl':'ltr'}">
            <strong> {{ service.servicesTitle[lang] }}</strong>
          </h3>
          <p :style="{direction:lang=='ara'?'rtl':'ltr'}">
            {{ service.servicesDescription[lang] }}
          </p>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        data-aos-duration="1000"
        data-aos="zoom-out-up"
        data-aos-anchor-placement="bottom-bottom"
      >
        <div style="width: 100%; text-align: center">
          <img src="@/assets/images/contact us.svg" height="95" style="margin-bottom:20px;" />
          <br />
        <v-row justify="center">
          <b-button block style="background-color: #00a7e1; width:fit-content;" @click="scroll">
            <h5 class="white--text" :id="contactUsBtn.id">
              <strong> {{contactUsBtn[lang]}} </strong>
            </h5></b-button
          >
        </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["servicesTitle", "servicesDetails"],
  computed: {
    origin() {
      return this.$store.state.origin;
    },
    lang() {
      return this.$store.state.lang;
    },
    contactUsBtn() {
      return this.$store.state.contactUsBtn;
    },
  },
  methods: {
    scroll() {
      document.getElementById("CONTACT").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.h3 {
  font-family: "Aero Matics Bold";
}
</style>
