import Repository from "@/repositories/Base/Repository";

export default class customerRepository extends Repository {
  constructor(datasource) {
    super(datasource);
  }

  all(){
    return this.datasource.get(`getOurCustomers`).then(({data}) => data)
  }
}