<template>
  <v-app style="width: 100vw">
    <v-main>
      <mid-nav
        :HomeBtn="HomeBtn"
        :OurTeamBtn="OurTeamBtn"
        :logo="logo"
        :facebook="setting.facebook"
        :twitter="setting.twitter"
        :linkedin="setting.linkedin"
        :blogsButton="blogsButton"
        :EventsButton="EventsButton"
        :historyTitle="historyTitle"
        :productsTitle="productsTitle"
        :memoriesTitle="memoriesTitle"
        :feedbackTitle="feedbackTitle"
        :language="language"
      />

      <router-view />
    </v-main>
    <foter
      v-show="!overlay"
      :logo="logo"
      :facebook="setting.facebook"
      :twitter="setting.twitter"
      :linkedin="setting.linkedin"
      :AboutContent="AboutContent"
    />
    <v-overlay :value="overlay">
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="70"
          :width="7"
        ></v-progress-circular>
      </v-row>
      <br />
      <h3 class="white--text">
        This website uses cookies to save your language preferences
      </h3>
    </v-overlay>
  </v-app>
</template>

<script>
import MidNav from "./components/MidNav.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import Foter from "./components/foter.vue";

export default {
  components: { MidNav, Foter },
  name: "App",
  inject: [
    "textRepo",
    "settingRepo",
    "newsRepo",
    "calenderRepo",
    "productsRepo",
    "feedbackRepo",
    "pheaderRepo",
  ],
  data: () => ({
    //
    text: {},
    HomeBtn: "",
    OurTeamBtn: "",
    ServicesTitle: "",
    WhyusTitle: "",
    ourCustomerTitle: "",
    ContactUs: "",
    AboutTitle: "",
    AboutContent: "",
    FollowUsTitle: "",
    setting: {},
    event: {},
    EventsButton: "",
    blogsButton: "",
    historyTitle: "",
    productsTitle: "",
    memoriesTitle: "",
    language: {},
    feedbackTitle: {},
  }),
  mounted() {
    AOS.init();
    this.textRepo.all().then((text) => {
      this.text = text["data"];
      this.HomeBtn = text["data"].filter((e) => e.id == 10001)[0];
      this.OurTeamBtn = text["data"].filter((e) => e.id == 10002)[0];
      this.ServicesTitle = text["data"].filter((e) => e.id == 10004)[0];
      this.WhyusTitle = text["data"].filter((e) => e.id == 10005)[0];
      this.ourCustomerTitle = text["data"].filter((e) => e.id == 10006)[0];
      this.ContactUs = text["data"].filter((e) => e.id == 10007)[0];
      this.EventsButton = text["data"].filter((e) => e.id == 10003)[0];
      this.blogsButton = text["data"].filter((e) => e.id == 10008)[0];
      this.$store.state.blogsTitle = text["data"].filter( (e) => e.id == 10008 )[0];
      this.$store.state.LastEventsTitle = text["data"].filter((e) => e.id == 10010)[0];
      this.AboutContent = text["data"].filter((e) => e.id == 10011)[0];
      this.$store.state.calenderTitle = text["data"].filter((e) => e.id == 10012)[0];
      this.historyTitle = text["data"].filter((e) => e.id == 10013)[0];
      this.productsTitle = text["data"].filter((e) => e.id == 10014)[0];
      this.$store.state.productsTitle = this.productsTitle;
      this.memoriesTitle = text["data"].filter((e) => e.id == 10015)[0];
      this.$store.state.memoriesTitle = this.memoriesTitle;
      this.$store.state.video = text["data"].filter((e) => e.id == 10017)[0].eng;
      this.$store.state.contactUsBtn = text["data"].filter((e) => e.id == 10018)[0];
      this.$store.state.showallbtn = text["data"].filter((e) => e.id == 10019)[0];
      this.$store.state.abouttitle = text["data"].filter((e) => e.id == 10020)[0];
      this.$store.state.FollowUsTitle = text["data"].filter((e) => e.id == 10021)[0];
      this.$store.state.ourtimeline = text["data"].filter((e) => e.id == 10022)[0];
      this.language = text["data"].filter((e) => e.id == 10023)[0];
      this.feedbackTitle = text["data"].filter((e) => e.id == 10024)[0];
      this.$store.state.feedbackTitle = this.feedbackTitle;
      this.$store.state.feedbackBtn = text["data"].filter((e) => e.id == 10025)[0];
      this.$store.state.fname = text["data"].filter((e) => e.id == 10026)[0];
      this.$store.state.femail = text["data"].filter((e) => e.id == 10027)[0];
      this.$store.state.fphone = text["data"].filter((e) => e.id == 10028)[0];
      this.$store.state.fsubject = text["data"].filter((e) => e.id == 10029)[0];
      this.$store.state.fdescription = text["data"].filter((e) => e.id == 10030)[0];
      this.$store.state.teammore = text["data"].filter((e) => e.id == 10031)[0];
      this.$store.state.videobtn = text["data"].filter((e) => e.id == 10032)[0];
      this.$store.state.from = text["data"].filter((e) =>e.id==10033)[0];
      this.$store.state.to = text["data"].filter((e)=> e.id == 10034)[0];
      this.$store.state.choosefile = text["data"].filter((e)=> e.id == 10035)[0];
      this.$store.state.selectrate = text["data"].filter((e)=> e.id == 10036)[0];
      this.$store.state.cfeedtitle = text["data"].filter((e)=> e.id == 10037)[0];
      this.$store.state.cfeeddescription = text["data"].filter((e) => e.id ==10038)[0];
      this.$store.state.csend= text["data"].filter((e) => e.id ==10039)[0];
      const payload = {
        ServicesTitle: this.ServicesTitle,
        WhyusTitle: this.WhyusTitle,
        ourCustomerTitle: this.ourCustomerTitle,
        ContactUs: this.ContactUs,
      };
      this.$store.commit("setText", payload);
    });
    this.settingRepo.all().then((response) => {
      this.setting = response["data"][0];
      this.$store.state.email = this.setting["PublicEmail"];
      this.$store.state.phone = this.setting["phone"];
      this.$store.state.street =
        this.setting["street"] +
        " " +
        this.setting["home_number_extention"] +
        ",";
      this.$store.state.city =
        this.setting["postcode"] + " " + this.setting["city"] + ",";
      this.$store.state.adress = this.setting["province"];
    });
    this.productsRepo.all().then((response) => {
      this.$store.state.products = response["data"];
    });
    this.newsRepo.all().then((response) => {
      this.$store.state.blogs = response["data"];
    });
    this.calenderRepo.all().then((response) => {
      this.$store.state.events = response["data"];
    });
    this.calenderRepo.memo().then((response) => {
      this.$store.state.memories = response["data"].memories;
    });
    this.feedbackRepo.all().then((response) => {
      this.$store.state.feedbacks = response["data"];
    });
    this.pheaderRepo.team().then((response) => {
      this.$store.state.theader = response["data"];
    });
    var value = "; " + document.cookie;
    var parts = value.split("; " + "clang" + "=");
    if (parts.length == 2) {
      this.$store.state.lang = parts.pop().split(";").shift();
      console.log(this.$store.state.lang);
    } else this.$store.state.lang = "eng";
  },
  computed: {
    overlay() {
      return this.$store.state.overlay;
    },
    origin() {
      return this.$store.state.origin;
    },
    logo() {
      return this.origin + "admin/files/images/setting/" + this.setting.logo;
    },
  },
};
</script>
<style>
.v-application {
  font-family: "QL";
}
.secTitle {
  font-size: 40px;
  width: fit-content;
  font-weight: 700;
}
.container {
  color: #005677;
}
.secTitle {
  font-size: calc(1.375+6vw);
  font-family: "Aero Matics Bold";
}
</style>
